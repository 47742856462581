import $ from 'jquery';
import 'chosen-js';

$('.chosen-select').chosen({
    allow_single_deselect: true,
    no_results_text: 'No results matched',
    width: '250px'
});

$(".navbar-toggle").click(function () {
    $("#navigation").slideToggle("slow");
    $(".navbar-toggle i").toggleClass("mdi-menu");
    $(".navbar-toggle i").toggleClass("mdi-close");
});
$(".comments-toggle").click(function () {
    $(this).children(".short-comments").toggle();
    $(this).children(".long-comments").toggle();
});

// status cards on dashboard...match height to tallest one
var biggest = 0;
$('.status-card').each(function () {
    if ($(this).height() > biggest) {
        biggest = $(this).height();
    }
});
$('.status-card').height(biggest);

// directory cards on dashboard...match height to tallest one
var biggestdc = 0;
$('.directory-card').each(function () {
    if ($(this).height() > biggestdc) {
        biggestdc = $(this).height();
    }
});
$('.directory-card').height(biggestdc);

// force navigation to be visible on larger sized screens -- in some cases, navigation may've
// been closed in mobile view and when window was resized larger the bar remained hidden
$(window).resize(function(){
    var windowwidth = $(window).width();

    if (windowwidth >= 900) {
        $("#navigation").slideDown("fast");
        $(".navbar-toggle i").removeClass("mdi-menu");
        $(".navbar-toggle i").addClass("mdi-close");
    }

});

// filter unregistered consultant list
$("#filterconsultant").on("change paste keyup", function() {
    $(".consultantrecord").hide();
    $('.consultantrecord').each(function(i, obj) {
        var nameval = $(this).find("td.nametd").text();
        var inputstr = $("#filterconsultant").val().toLowerCase();
        if(nameval.toLowerCase().indexOf(inputstr) >= 0) {
            $(this).show();
        }
    });
});

// filter registered consultant list
$("#filterregisteredconsultant").on("change paste keyup", function() {
    $(".registeredconsultantrecord").hide();
    $('.registeredconsultantrecord').each(function(i, obj) {
        var nameval = $(this).find("td.nametd").text();
        var inputstr = $("#filterregisteredconsultant").val().toLowerCase();
        if(nameval.toLowerCase().indexOf(inputstr) >= 0) {
            $(this).show();
        }
    });
});

// load HTML Editor
if($("#editorone").length > 0){
    // to avoid glitch where tinymce editor doesn't always load on every page visit, remove the editor instance
    tinyMCE.execCommand('mceRemoveEditor', false, 'editorone');
    // then load editor
    tinymce.init({
        selector: "textarea#editorone",
        theme: "silver",
        height:250,
        plugins: [
            "autolink lists charmap hr pagebreak searchreplace wordcount code paste"
        ],
        toolbar: "bold italic | bullist numlist outdent indent | forecolor backcolor",
    });
}

// File Preview
var imgPreview = $("img#file-preview");

$("#file-preview-field").change(function(event){
    var input = $(event.currentTarget);
    var file = input[0].files[0];
    var reader = new FileReader();
    reader.onload = function(e){
        var image_base64 = e.target.result;
        imgPreview.attr("src", image_base64);
    };
    reader.readAsDataURL(file);
});

// Multiple file preview

$(".file-preview-holder .file-preview-field").change(function(event){
    var input = $(event.currentTarget);
    var file = input[0].files[0];
    var reader = new FileReader();
    var imgPreview = $(this).closest('.file-preview-holder').find('img.file-preview')
    reader.onload = function(e){
        var image_base64 = e.target.result;
        imgPreview.attr("src", image_base64);
        imgPreview.removeClass('dnone');
    };
    reader.readAsDataURL(file);
});

// User Reports

$('.agency-summary-table tr.summary').click(function() {
    var moreInfo = $(this).closest('tbody').find('.more-info');
    var isOpen = $(this).hasClass('open');
    if (isOpen) {
        moreInfo.addClass('dnone');
        $(this).removeClass('open');
    } else {
        closeAllSummaries();
        moreInfo.removeClass('dnone');
        $(this).addClass('open')
    }
});

function closeAllSummaries() {
    $('.agency-summary-table tr.summary').each(function() {
        closeSummary($(this));
    });
}

function closeSummary(summary) {
    var moreInfo = summary.closest('tbody').find('.more-info');
    var isOpen = summary.hasClass('open');
    if (isOpen) {
        moreInfo.addClass('dnone');
        summary.removeClass('open');
    }
}

// Register States

$('.register-btn').click(function() {
    var registerOptions = $(this).next('.register-options');
    var isHiddenOptions = registerOptions.hasClass('dnone');
    $('.register-options').addClass('dnone');

    if (isHiddenOptions) {
        registerOptions.removeClass('dnone')
    }
});

$('.territories-register-btn').click(function() {
    var registerOptions = $(this).next('.territories-register-options');
    var isHiddenOptions = registerOptions.hasClass('dnone');
    $('.territories-register-options').addClass('dnone');

    if (isHiddenOptions) {
        registerOptions.removeClass('dnone')
    }
});

// Territory Checks Mass Input
$('#notes-card .fa-window-close').click(function() {
    $("#notes-card").slideUp("slow");
});
$('.filter-card .comment-active, .filter-card .comment-inactive').click(function() {
    $("#notes-card").slideDown("slow");
});
$('.notes-card-sub .fa-times').click(function() {
    $(this).closest(".notes-card-td").slideUp("fast");
});
$('.comment-btn').click(function() {
    $(this).closest("tr").prev("tr").find(".notes-card-td").slideToggle("fast");
});
$('.filter-card .activecontent .fa-times-circle').click(function() {
    $(this).parent(".activecontent").hide();
    $(this).parent().next(".placeholder").show();
});
$('.mi-status-card').click(function() {
    var thisobj = $(this);
    if (thisobj.hasClass('territory-status-1')) {
        thisobj.removeClass('territory-status-1').addClass('territory-status-3')
            .find('i.mdi').removeClass('mdi-checkbox-blank-circle-outline').addClass('mdi-checkbox-blank-circle');
        thisobj.find('input[type=hidden]').val(0);
    } else {
        thisobj.removeClass('territory-status-3').addClass('territory-status-1')
            .find('i.mdi').removeClass('mdi-checkbox-blank-circle').addClass('mdi-checkbox-blank-circle-outline');
        thisobj.find('input[type=hidden]').val(1);
    }
});
$('.edit-row-btn').click(function() {
    var thisobj = $(this);
    var tr = thisobj.closest('tr');

    if (thisobj.attr('editing')) {
        thisobj.removeAttr('editing');
        tr.find('.row-editor').hide();
        tr.find('.save-row-btn').hide();
        tr.find('.row-editable').show();
        tr.find('.delete-row-btn').show();
        thisobj.text('Edit');
    } else {
        thisobj.attr('editing', true);
        tr.find('.row-editor').show();
        tr.find('.save-row-btn').show();
        tr.find('.row-editable').hide();
        tr.find('.delete-row-btn').hide();
        thisobj.html('<i class="fa fa-times">');
    }
});

// KPI Dashboard

$('.kpi-division-card').click(function() {
    var divisionID = $(this).attr('division_id');
    $.post('/session_store_kpi_division_id', {division_id: divisionID});
});

$('.kpi-ranker-sort').click(function() {
    var sortBy = $(this).attr('sort_by');
    $.post('/session_store_kpi_ranker_sort', {sort_by: sortBy})
        .done(function() {
            location.reload();
        });
});

$('.sales-goal-show').click(function() {
    $(this).addClass('dnone');
    $(this).next('.sales-goal-edit').removeClass('dnone').find('input').select();
});

$('.sales-goal-edit input').blur(function() {
    var salesGoalEdit = $(this).closest('.sales-goal-edit');
    salesGoalEdit.addClass('dnone');
    salesGoalEdit.prev('.sales-goal-show').removeClass('dnone');
});

$('.leads-goal-show').click(function() {
    $(this).addClass('dnone');
    $(this).next('.leads-goal-edit').removeClass('dnone').find('input').select();
});

$('.leads-goal-edit input').blur(function() {
    var leadsGoalEdit = $(this).closest('.leads-goal-edit');
    leadsGoalEdit.addClass('dnone');
    leadsGoalEdit.prev('.leads-goal-show').removeClass('dnone');
});

$('.territory-checks-goal-show').click(function() {
    $(this).addClass('dnone');
    $(this).next('.territory-checks-goal-edit').removeClass('dnone').find('input').select();
});

$('.territory-checks-goal-edit input').blur(function() {
    var territoryChecksGoalEdit = $(this).closest('.territory-checks-goal-edit');
    territoryChecksGoalEdit.addClass('dnone');
    territoryChecksGoalEdit.prev('.territory-checks-goal-show').removeClass('dnone');
});

$('.pipeline-goal-show').click(function() {
    $(this).addClass('dnone');
    $(this).next('.pipeline-goal-edit').removeClass('dnone').find('input').select();
});

$('.pipeline-goal-edit input').blur(function() {
    var pipelineGoalEdit = $(this).closest('.pipeline-goal-edit');
    pipelineGoalEdit.addClass('dnone');
    pipelineGoalEdit.prev('.pipeline-goal-show').removeClass('dnone');
});

$('.dday-committed-goal-show').click(function() {
    $(this).addClass('dnone');
    $(this).next('.dday-committed-goal-edit').removeClass('dnone').find('input').select();
});

$('.dday-committed-goal-edit input').blur(function() {
    var ddayCommittedGoalEdit = $(this).closest('.dday-committed-goal-edit');
    ddayCommittedGoalEdit.addClass('dnone');
    ddayCommittedGoalEdit.prev('.dday-committed-goal-show').removeClass('dnone');
});

window.initAutocompletes = function () {
  // BRANDS AUTOCOMPLETE

  var brandsAutocomplete = $('#brands-autocomplete');
  brandsAutocomplete.on('input', function() {
      var value = $(this).val().toLowerCase();
      autocompleteInput(value, 'brands');
  });
  brandsAutocomplete.keydown(function(e) {
      autocompleteKeydown(e, 'brands');
  });
  $('.brands-autocomplete-option').on('click', function() {
      var value = $(this).attr('data_id');
      var text = $(this).text();
      selectAutocompleteValue(value, text, 'brands');
  });

  // BRANDS FILTER AUTOCOMPLETE

  var brandsFilterAutocomplete = $('#brands-filter-autocomplete');
  brandsFilterAutocomplete.on('input', function() {
      var value = $(this).val().toLowerCase();
      autocompleteInput(value, 'brands-filter');
  });
  brandsFilterAutocomplete.keydown(function(e) {
      autocompleteKeydown(e, 'brands-filter');
  });
  $('.brands-filter-autocomplete-option').on('click', function() {
      var value = $(this).attr('data_id');
      var text = $(this).text();
      selectAutocompleteValue(value, text, 'brands-filter');
  });

  // BROKERS AUTOCOMPLETE

  var brokersAutocomplete = $('#brokers-autocomplete');
  brokersAutocomplete.on('input', function() {
      var value = $(this).val().toLowerCase();
      autocompleteInput(value, 'brokers');
  });
  brokersAutocomplete.keydown(function(e) {
      autocompleteKeydown(e, 'brokers');
  });
  $('.brokers-autocomplete-option').on('click', function() {
      var value = $(this).attr('data_id');
      var text = $(this).text();
      selectAutocompleteValue(value, text, 'brokers');
  });

  // GENERIC BADGE AUTOCOMPLETE

  var genericAutocomplete = $('.generic-autocomplete');
  genericAutocomplete.on('input', function() {
      var identifier = $(this).attr('identifier');
      var value = $(this).val().toLowerCase();
      autocompleteInput(value, identifier);
  });
  genericAutocomplete.on('focus', function() {
      var identifier = $(this).attr('identifier');
      var value = $(this).val().toLowerCase();
      if (value.length === 0) {
          autocompleteInput(null, identifier);
      }
  });
  genericAutocomplete.keydown(function(e) {
      var identifier = $(this).attr('identifier');
      autocompleteKeydown(e, identifier);
  });
  $('.generic-autocomplete-option').on('click', function() {
      var identifier = $(this).attr('identifier');
      var text = $(this).text();
      selectAutocompleteValue(text, text, identifier);
  });
}

// AUTOCOMPLETE HELPERS

function autocompleteInput(value, identifier) {
    $('#'+identifier+'-autocomplete-options').find('.'+identifier+'-autocomplete-option').each(function(index, option) {
        var optionElement = $(option);
        var description = $(option).text();
        // if value is null, show all options
        if (value !== null && (value.length === 0 || !description.toLowerCase().match(value))) {
            optionElement.addClass('dnone');
        } else {
            optionElement.removeClass('dnone dnone-temp');
        }
    });
    autocompleteOptionsUpdateStyle(identifier);
}
function autocompleteKeydown(e, identifier) {
    var code = e.keyCode || e.which;
    // Tab: 9, Enter: 13, Up: 38, Down: 40
    if (code === 9 || code === 13 || code === 38 || code === 40) {
        e.preventDefault();
        autocompletePressKey(code, identifier, true);
    }
}
function autocompletePressKey(code, identifier, tabOnEnter) {
    var firstOption = $('.'+identifier+'-autocomplete-option').not('.dnone').not('.dnone-temp').first();
    if (code === 9 || code === 13) {
        if (firstOption.length) {
            firstOption.trigger('click');
        } else if (code === 13) {
            // Select null if pressed Enter
            selectAutocompleteValue(null, null, identifier);
        }
        if (code === 9 || tabOnEnter) {
            // Tab to next input
            var current = $('#'+identifier+'-autocomplete');
            var nextTabTarget = current.attr('next_tab_target');
            if (nextTabTarget) {
                $(nextTabTarget).focus();
            } else {
                current.closest('.form-group').parent().next().find('.form-group input').focus();
            }
        }
    } else if (code === 38) {
        $('.'+identifier+'-autocomplete-option.dnone-temp').not('.dnone').last().removeClass('dnone-temp');
        autocompleteOptionsUpdateStyle(identifier);
    } else if (code === 40) {
        if (firstOption.length) {
            firstOption.addClass('dnone-temp');
            autocompleteOptionsUpdateStyle(identifier);
        } else {
            var value = $('#'+identifier+'-autocomplete').val().toLowerCase();
            if (value.length === 0) {
                // Passing a null value shows all options
                value = null;
            }
            autocompleteInput(value, identifier);
        }
    }
}

function selectAutocompleteValue(value, text, identifier) {
    var field = $('#'+identifier+'-field');
    var badge = $('#'+identifier+'-badge');
    var autocompleteField = $('#'+identifier+'-autocomplete');

    field.val(value);
    autocompleteField.val('').trigger('input');
    if (value) {
        badge.removeClass('vhidden').find('.badge-text').text(text);
    } else if (identifier === 'brokers') {
        badge.removeClass('vhidden').find('.badge-text').html('Organic');
    } else {
        badge.addClass('vhidden').find('.badge-text').html('&nbsp;');
    }
}

function autocompleteOptionsUpdateStyle(identifier) {
    var foundFirst = false;
    $('#'+identifier+'-autocomplete-options').find('.'+identifier+'-autocomplete-option').not('.dnone').not('.dnone-temp').each(function(index, option) {
        var optionElement = $(option);
        if (!foundFirst) {
            optionElement.addClass('autocomplete-option-highlighted');
            foundFirst = true;
        } else {
            optionElement.removeClass('autocomplete-option-highlighted');
        }
    });

}

// OTHERS
var badgeTextInput = $('input.badge-text-input');

badgeTextInput.on('input', function() {
    var value = $(this).val();
    var parent = $(this).closest('.form-group');
    var hiddenField = parent.find('input[type=hidden]');
    var badge = parent.find('.badge');

    // Update Hidden field value
    hiddenField.val(value);

    if (value) {
        // Show badge with value
        badge.removeClass('vhidden').find('.badge-text').text(value);
    } else {
        // Hide badge
        badge.addClass('vhidden').find('.badge-text').html('&nbsp;');
    }
});
badgeTextInput.keydown(function(e) {
    var code = e.keyCode || e.which;
    if (code === 9 || code === 13) { // Tab or Enter
        e.preventDefault();
        var value = $(this).val();
        var parent = $(this).closest('.form-group');
        var hiddenField = parent.find('input[type=hidden]');
        var badge = parent.find('.badge');

        if (value || code === 13) {
            // Update value if pressed enter or
            // if tab while value present.

            if (!$(this).is('input[type=date]')) {
                // Reset text input unless this is a date field
                $(this).val('');
            }

            // Update Hidden field value
            hiddenField.val(value);

            if (value) {
                // Show badge with value
                badge.removeClass('vhidden').find('.badge-text').text(value);
            } else {
                // Hide badge
                badge.addClass('vhidden').find('.badge-text').html('&nbsp;');
            }
        }

        // Tab to next field
        parent.closest('.form-group').parent().next().find('.form-group input').focus();
    }
});

// EXECUTE

// Press Enter on autocomplete fields
autocompletePressKey(13, 'brands');
autocompletePressKey(13, 'brokers');

// KPI NOTES BUBBLE

var kpiNotesIcon = $('.kpidash-notes-bin i');
var kpiBubbleReadonly = $('.kpidash-notes-bin .bubble .readonly-admin');
var kpiBubbleEditableCancel = $('.kpidash-notes-bin .bubble .editable-form .cancel-button');

kpiNotesIcon.on('click', function () {
    var bubble = $(this).closest('.kpidash-notes-bin').find('.bubble');
    if (bubble.hasClass('dnone')) {
        bubble.removeClass('dnone');
    } else {
        bubble.find('.cancel-button').trigger('click');
        bubble.addClass('dnone');
    }
});
kpiBubbleReadonly.on('click', function() {
    $(this).addClass('dnone');
    $(this).closest('.bubble').find('.editable-form').removeClass('dnone').find('textarea').focus();
});
kpiBubbleEditableCancel.on('click', function() {
    $(this).closest('.editable-form').addClass('dnone');
    $(this).closest('.bubble').find('.readonly-admin').removeClass('dnone');
});

function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            $('#brand-logo')
                .attr('src', e.target.result);
                $('#brand-logo').css("border",0);
        };

        reader.readAsDataURL(input.files[0]);
    }
}
function readURL2(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            $('#brand-photo')
                .attr('src', e.target.result);
                $('.brand-photo-crop').css("border",0);
        };

        reader.readAsDataURL(input.files[0]);
    }
}
