import React from 'react';
import { usePagination, DOTS } from './hooks/usePagination';
import { Button, Flex } from 'antd';
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <Flex gap="small" wrap="wrap" justify='flex-end'>
      <Button
        style={{ backgroundColor: '#5b626b', color: 'white' }}
        size="small" 
        disabled={currentPage === 1} 
        onClick={() => onPageChange(1)}
      >
        « First
      </Button>
      <Button
        style={{ backgroundColor: '#5b626b', color: 'white' }}
        size="small"
        disabled={currentPage === 1}
        onClick={onPrevious}
      >
        ‹ Prev
      </Button>
      {paginationRange.map(pageNumber => {
          if (pageNumber === DOTS) {
            return <div style={{paddingTop: "8px"}}>&#8230;</div>;
          }

          const isCurrent = pageNumber === currentPage
          return (
            <Button
              size='small'
              type='text'
              disabled={isCurrent}
              style={{ backgroundColor: isCurrent ? '#fd54dd' : null, color: isCurrent ? 'white' : '#5b626b' }}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </Button>
          );
        })}
      <Button
        size="small"
        disabled={lastPage === currentPage}
        onClick={onNext}
        style={{ backgroundColor: '#5b626b', color: 'white' }}
      >
        Next ›
      </Button>
      <Button
        size="small"
        disabled={lastPage === currentPage}
        onClick={() => onPageChange(lastPage)}
        style={{ backgroundColor: '#5b626b', color: 'white' }}
      >
        Last »
      </Button>
    </Flex>
  );
};

export default Pagination;
