import React, { useState, useMemo, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import Pagination from './Pagination';
import { Button } from 'antd';
import checkStatus from './helpers/checkStatus';

export default function UnregisteredConsultants({ list }) {
  const [pending, setPending] = useState(null)
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const data = list.filter(x => x.name.toLowerCase().includes(search.toLowerCase()))
  const onSearch = (e) => {
    setSearch(e.target.value)
    setCurrentPage(1)
  }

  const pageSize = 10
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data]);


  const csrfToken = document.querySelector("[name='csrf-token']").content

  const options = {
    method: 'POST',
    headers: { "X-CSRF-Token": csrfToken, "content-type": 'application/json' },
  };

  function sendInvite(id) {
    setPending(id)
    fetch(`/api/brokers/${id}/send_broker_invite_email`, options)
    .then(checkStatus)
    .then(r => console.log(r))
    .catch((e) => console.log(e.errors))
    .finally(() => setPending(false))
  }

  return(
    <>
      <h5 class="mt-0">Unregistered Consultants List</h5>
      <div style={{ marginBottom: "15px" }}>
        <input class="form-control" onChange={onSearch} placeholder="Filter By Name" type="text" />
      </div>
      <table class="table table-thin">
        <tbody>
          {currentTableData.map((c) => (
            <tr class="consultantrecord">
              <td class="nametd">{c.name}</td>
              <td>{c.email}</td>
              <td>Last Invitation Sent: {c.sent_at}</td>
              <td>
              <Button
                  style={{ backgroundColor: '#fd54dd', color: 'white' }}
                  size="small"
                  onClick={() => sendInvite(c.id)}
                  disabled={!!pending}
                >
                  {
                    pending === c.id
                      ? "Resending..."
                      : "Invite"
                  }
                </Button>
                <div class="clearfix"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={data.length}
        pageSize={pageSize}
        onPageChange={page => setCurrentPage(page)}
      />
    </>
  );
}

// document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('unregistered-consultants');
  if (element) {
    const list = JSON.parse(element.getAttribute('data-list'))
    const root = createRoot(element);
    root.render(<UnregisteredConsultants list={list} />);
  }
// });
