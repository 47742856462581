import '../javascripts/jqueryLoader';

import Rails from '@rails/ujs';
import 'bootstrap';
import 'jquery-sparkline';
import '../javascripts/froogaloop';
import '../javascripts/jquery.fitvid';
import '../javascripts/jquery.flexslider';
import '../javascripts/jquery.slimscroll';
import '../javascripts/custom';
import '../javascripts/ConsultantRanker';
import '../javascripts/DirectorRanker';
import '../javascripts/RegisteredConsultants';
import '../javascripts/UnregisteredConsultants';
import '../javascripts/tinymce';

import init from '../javascripts/app';
Rails.start();
init()
