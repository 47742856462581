/*
 Template Name: lexa - Responsive Bootstrap 4 Admin Dashboard
 Author: Themesbrand
 Website: www.themesbrand.com
 File: Main js
 */

function initNavbar() {
    $('.navbar-toggle').on('click', function (event) {
        $(this).toggleClass('open');
        $('#navigation').slideToggle(400);
    });

    $('.navigation-menu>li').slice(-2).addClass('last-elements');

    $('.navigation-menu li.has-submenu a[href="#"]').on('click', function (e) {
        if ($(window).width() < 992) {
            e.preventDefault();
            $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
        }
    });
}

function initScrollbar() {
    $('.slimscroll').slimScroll({
        height: 'auto',
        position: 'right',
        size: "7px",
        color: '#9ea5ab',
        wheelStep: 5,
        touchScrollStep: 50
    });
}

// === fo,llowing js will activate the menu in left side bar based on url ====
function initMenuItem() {
    $(".navigation-menu a").each(function () {
        var pageUrl = window.location.href.split(/[?#]/)[0];
        if (this.href == pageUrl) { 
            $(this).parent().addClass("active"); // add active to li of the current link
            $(this).parent().parent().parent().addClass("active"); // add active class to an anchor
            $(this).parent().parent().parent().parent().parent().addClass("active"); // add active class to an anchor
        }
    });
}

function initComponents() {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  $('#lead-detail').modal('show');
  $('#intro-video').modal('show');
  $('#overview-video').modal('show');
  $('#summer-prize-fran-choice').modal('show');

  $('.notice-modal-trigger').on('click', function () {
    $('#noticemodalimg').attr('src', ($(this).attr('src')));
  })
}

function initHeaderCharts() {
    $('#header-chart-1').sparkline([8, 6, 4, 7, 10, 12, 7, 4, 9, 12, 13, 11, 12], {
        type: 'bar',
        height: '35',
        barWidth: '5',
        barSpacing: '3',
        barColor: '#7A6FBE'
    });
    $('#header-chart-2').sparkline([8, 6, 4, 7, 10, 12, 7, 4, 9, 12, 13, 11, 12], {
        type: 'bar',
        height: '35',
        barWidth: '5',
        barSpacing: '3',
        barColor: '#29bbe3'
    });
}

export default function init() {
  initNavbar();
  initScrollbar();
  initMenuItem();
  initComponents();
  initHeaderCharts();
//   Waves.init();
}
