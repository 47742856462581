import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DirectorRanker() {
  const year = new Date().getFullYear()
  
  const todaysDate = new Date().toISOString().substring(0, 10);
  const startOfQuarterDate = Math.floor((new Date().getMonth() / 3)); 
  const firstDayOfWeek = new Date().getDate() - new Date().getDay(); // First day is the day of the month - the day of the week
  const startOfWeek = new Date(new Date().setDate(firstDayOfWeek)).toISOString().substring(0, 10);
  const startOfMonth = new Date(year, new Date().getMonth(), 1).toISOString().substring(0, 10);
  const startOfQuarter = new Date(year, startOfQuarterDate * 3, 1).toISOString().substring(0, 10);
  const startOfYear = new Date(year, 0, 1).toISOString().substring(0, 10);

  const dateRanges = {
    today: {start_date: todaysDate, end_date: todaysDate},
    this_week: {start_date: startOfWeek, end_date: todaysDate},
    this_month: {start_date: startOfMonth, end_date: todaysDate},
    this_quarter: {start_date: startOfQuarter, end_date: todaysDate},
    this_year: {start_date: startOfYear, end_date: todaysDate},
  }

  const [filter, setFilterWhenNotLoading] = useState({start_date: startOfQuarter, end_date: todaysDate})
  const [manualFilter, setManualFilter] = useState({})
  const [pageList, setPageList] = useState([]);
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortBy, setSortBy] = useState('units_sold');
  const [currentTimeframe, setCurrentTimeframe] = useState('this_quarter');
  const [loading, setLoading] = useState(false);

  const setFilter = (f) => {
    if (!loading) setFilterWhenNotLoading(f)
  }

  useEffect(() => {
    setLoading(true)
    async function fetchData() {
      const searchParams = new URLSearchParams({ ...filter, sort_direction: sortDirection, sort_by: sortBy })
      const response = await fetch("api/director_rankings?" + searchParams);
      const list = await response.json();
      return (list)
    }
    // call the function
    fetchData()
      .then((res) => {
        setPageList(res)
        setLoading(false)
      })
      // make sure to catch any error
      .catch(console.error);
  }, [JSON.stringify({...filter, sort_by: sortBy, sort_direction: sortDirection})])

  const headers = [
    { key: 'director', label: 'Director' },	 
    { key: 'units_sold', label: 'Units Sold(Sales Count)' },
    { key: 'leads', label: 'Leads' },
    { key: 'active_pipeline', label: 'Active Pipeline' },
    { key: 'laggard_rate', label: 'Laggard Rate' },	
    { key: 'upcoming_c_day', label: 'Upcoming CDay' },
    { key: 'close_rate_ytd', label: 'Close Rate YTD' },
  ]

  const setSort = (key) => {
    if (!loading) {
      if (key === sortBy) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
      } else {
        setSortBy(key)
        setSortDirection('desc')
      }
    }
  }
  
  return (
    <div aria-labelledby="ranker-tab" className="tab-pane fade active show" id="ranker" role="tabpanel">
      <div className="row">
        <div className="col-12">
          <div className="card m-b-20">
            <div className="card-body">
              <div className="m-b-10">&nbsp;</div>
                <div className="quick-daterange-bin">
                  <button 
                    className={classNames("btn btn-primary", currentTimeframe === 'today' ? 'disabled' : null )}
                    onClick={() => {
                      setFilter({...filter, ...dateRanges.today})
                      setCurrentTimeframe('today')
                      setManualFilter({})
                    }}
                  >
                      Today
                  </button>
                  <button 
                    className={classNames("btn btn-primary", currentTimeframe === 'this_week' ? 'disabled' : null )} 
                    onClick={() => {
                      setFilter({...filter, ...dateRanges.this_week})
                      setCurrentTimeframe('this_week')
                      setManualFilter({})
                    }}
                  >
                    This Week
                  </button>
                  <button 
                    className={classNames("btn btn-primary", currentTimeframe === 'this_month' ? 'disabled' : null )}
                    onClick={() => {
                      setFilter({...filter, ...dateRanges.this_month})
                      setCurrentTimeframe('this_month')
                      setManualFilter({})
                    }}
                    >This Month
                  </button>
                  <button 
                    className={classNames("btn btn-primary", currentTimeframe === 'this_quarter' ? 'disabled' : null )}
                    onClick={() => {
                      setFilter({...filter, ...dateRanges.this_quarter})
                      setCurrentTimeframe('this_quarter')
                      setManualFilter({})
                    }}
                  >
                    This Quarter
                  </button>
                  <button 
                    className={classNames("btn btn-primary", currentTimeframe === 'this_year' ? 'disabled' : null )}
                    onClick={() => {
                      setFilter({...filter, ...dateRanges.this_year})
                      setCurrentTimeframe('this_year')
                      setManualFilter({})
                    }}
                  >
                    This Year
                  </button>
                </div>
                <div>{loading ? "loading..." : null}</div>
                <div className="daterange-bin">
                  <form action="/kpidashboard" acceptCharset="UTF-8" method="get"><div className="form-inline">
                  Custom Date Range:
                  <input 
                    type="date" 
                    name="start_date" 
                    id="start_date2" 
                    value={manualFilter.start_date || filter.start_date} 
                    className="form-control m-l-5 m-r-5"
                    onChange={(e) => setManualFilter({ ...manualFilter, start_date: e.target.value})}
                  />
                  ‐
                  <input
                    type="date"
                    name="end_date"
                    id="end_date2"
                    value={manualFilter.end_date || filter.end_date} 
                    className="form-control m-l-5 m-r-5"
                    onChange={(e) => setManualFilter({ ...manualFilter, end_date: e.target.value})}
                  />
                  <input
                    type="button" 
                    name="commit" 
                    value="Go" 
                    className={"btn btn-primary"} 
                    data-disable-with="Go"
                    onClick={() => {
                      setCurrentTimeframe('')
                      const start_date = manualFilter.start_date || filter.state_date;
                      const end_date = manualFilter.end_date || filter.end_date;
                      setFilter({start_date, end_date})
                      setManualFilter({})
                    }}
                  />
                  </div>
                  </form>
                </div>
              <table className="table table-striped table-condensed kpitable3">
              <tbody>
              <tr className="cell-color-clear">
              <td className="cell-color-dark">Rank</td>
              {
                headers.map(({ label, key }) => {
                  const arrowDirection = sortBy === key 
                    ? sortDirection === 'desc' ? 'down' : 'up'
                    : 'right' 
                  return (
                  <td className="cell-color-dark cursor-pointer kpi-ranker-sort" sort_by={key} key={key} onClick={() => setSort(key)}>
                    {label}
                    <div className="float-right">
                      <i className={`mdi mdi-arrow-${arrowDirection}-drop-circle`}/>
                    </div>
                    <div className="clearfix"></div>
                  </td>
                )
                })
              }
              </tr>
              {
                pageList.map((listItem, idx) => (
                  <tr key={`${listItem.id}${idx}`}>
                  <td className="result-logo">{sortDirection === 'desc' ? idx + 1 : pageList.length - idx}</td>
                  <td className="result-sm">{listItem.name}</td>
                  <td className="result-sm">{listItem.unit_count || 0}({listItem.sales_count})</td>
                  <td className="result-sm">{listItem.lead_count}</td>
                  <td className="result-sm">{listItem.pipeline_count}</td>
                  <td className="result-sm">{listItem.laggard_rate}%</td>
                  <td className="result-sm">{listItem.cday_count}</td>
                  <td className="result-sm">
                    {listItem.closed_ytd} / {listItem.lead_ytd_count} * 100 ={' '}
                    {parseFloat(listItem.closed_percentage_ytd * 100).toFixed(1)}%
                  </td>
                  </tr>
                ))
              }
              </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('director-ranker-table');
  if (element) {
    const root = createRoot(element);
    root.render(<DirectorRanker/>);
  }
// });
